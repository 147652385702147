import { collection, collectionGroup, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "./firebase";



export const getCountry = () => {
    try{
        const q = query(
            collection(db, "country")
        )

        return q


    }catch(error){

    }
}

export const getAllData = async() => {
    try{
        const fields = ["Distributors", "Dealers"]

        let filterData = []
        for (const field of fields) {
            const q = query(collection(db, field));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async(docData) => {
                let copy = docData.data();

                copy['entity'] = field

                filterData = filterData.concat(copy)
            })
        }

        filterData.sort((a, b) => {
            if( a?.company && b?.company ){
                return a['company'].localeCompare(b['company'])
            }
        });

        return filterData
    }catch(error){

    }
}

