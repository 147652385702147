import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";





function List({clickEntity, hoveredMarker, clickContry, AllDAta, searchByName, disableCountry, listClick, setListClick, setHoveredMarker, setDefaultProps}){

    const onClickMap = (idx, data) =>{
        if(listClick === idx){
          setListClick()
          setHoveredMarker(null)
        }else{
          setListClick(idx)
          setHoveredMarker(data)
  
          setDefaultProps({
            center: {
              lat: data?.lat,
              lng: data?.lng
            },
            zoom: 18
          })
        }
    }

      useEffect(() => {
        if(hoveredMarker){
            AllDAta?.map(( data, idx ) => {
              if (JSON.stringify(hoveredMarker) === JSON.stringify(data)){
                setListClick(idx)
                handleClickScroll(idx)
                setDefaultProps({
                  center: {
                    lat: data?.lat,
                    lng: data?.lng
                  },
                  zoom: 18
                })
              }
            })
  

        }else{
          setListClick()
          setHoveredMarker(null)
        }
        
      },[hoveredMarker])
  


    const handleClickScroll = (idx) => {
        const element = document.getElementById(idx);
        const container = document.getElementById('scroll-container'); // 스크롤 가능한 컨테이너의 ID
      
        if (element && container) {
          // 요소가 컨테이너 안에 있을 경우에만 스크롤
          if (container.contains(element)) {
            // 요소의 위치에서 컨테이너 상단으로부터의 거리를 계산하여 일정한 여백을 두고 스크롤
            const offsetTop = element.offsetTop - container.offsetTop; // 요소의 위치 - 컨테이너의 위치
            // 스크롤 가능한 컨테이너에서 요소의 위치까지 부드럽게 스크롤
            container.scrollTo({
              top: offsetTop,
              behavior: 'smooth'
            });
          }
        }
      };

    return(

        <div id="scroll-container">
            {(clickContry && AllDAta) ? AllDAta?.map(( data, idx ) => (
            ( (clickEntity === 'all' || clickEntity === data.entity) && 
                (clickContry[0] === 'Country' ||  clickContry[0] === data.country) &&
                (searchByName === '' ||  data.company.includes(searchByName)) &&
                (!disableCountry?.some((country) => country[0] === data.country))
            ) &&
            <div 
                id={idx}
                name={idx}
                key={idx} 
                onClick={() => onClickMap(idx, data)}
                style={{
                'backgroundColor' : listClick === idx ?  data.entity === 'Distributors' ? '#000000' : '#B18658' : '#ffffff', 
                'color' : listClick === idx && '#ffffff'}}
            >
                <nav>
                <h4>{data.company}</h4>
                <div>{data.entity}</div>
                </nav>
                <div>{data.address}</div>
                <div>{data.country}</div>
                <div>TEL : {data.contact}</div>
                {data.email !== '' && <div> E-mail : {data.email}</div>}
                <div>URL : {data.homepage}</div>
            </div>
            )) : 
                <Skeleton count={50}/>
            }
        </div>
    )
}

export default List;