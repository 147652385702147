import { useEffect, useState } from "react";
import styled from "styled-components";
import { getCountryImg } from "../../db/storage";
import { getCountry } from "../../db/query";
import { getDocs } from "firebase/firestore";
import { motion } from "framer-motion";
import UseWidhtEvent from "../../hook/setWidthEvent";



function Filter({setCkickEvent, clickContry, setClickContry, setPlaceSearch, setDisable}){
    const [countryData, setCountryData] = useState();
    const [countryDrop, setCountryDrop] = useState(false);
    const { onOff } = UseWidhtEvent(1130)
    const [search, setSearch] = useState('')

    useEffect(() => {
        const country = async() => {
            const img = await getCountryImg();

            const country = getCountry()
            const query = await getDocs(country);
            let filterData = []
            if(img){
                query.forEach((doc) => {
                    filterData = filterData.concat(Object.entries(doc.data()))
                })

                filterData.sort((a, b) => a[0].localeCompare(b[0]));

            }

            filterData.map(( data, idx ) => {
                img.map(( image) => {
                   
                    if(image.includes(data[0].replace(' ', '%20'))){
                        data[1]['img'] = image
                        
                    }
                })
            })

            filterData.unshift(['Country', {"label" : 'Country', 'activation': true, 'img':null}])

            setCountryData(filterData)
            setClickContry(filterData[0])

        }

        country()
    },[])

    useEffect(() => {
        if(countryData){
            const disableCountry = countryData.filter((data) => {
                return !data[1].activation;
            });

            setDisable(disableCountry)
        }
    },[countryData])

    return(
        (clickContry && countryData )?
        <Layout data={clickContry[1]?.label} onOff={onOff}>
            
            <ul>
                <div onClick={() => setCountryDrop(e => !e)} > 
                    <div>{clickContry[1]?.label}</div>
                    <img src="images/list.png" />
                </div>
                <motion.nav style={{ "display" : countryDrop ? "block" : "none"}}>
                    {countryData && countryData.map((data) => (
                        data[1].activation && clickContry[0] !== data[0] && 
                        <div key={data[0]} onClick={() => {
                            setClickContry(data)
                            setCountryDrop(e => !e)
                        }}>
                            <img src={data[1].img} />
                            <div>{data[1].label}</div>
                        </div>
                    ))}
                </motion.nav>
            </ul>


            <SearchBox onOff={onOff}>
                <nav>
                    <input 
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        placeholder="City, Street, Address, Postcode"
                        onKeyDown={ e =>  e.key === "Enter" && setPlaceSearch(search)}
                    />
                    <div onClick={() => setPlaceSearch(search)} >Search</div>
                </nav>
                
                <div onClick={() => setCkickEvent(e => !e)}>
                    <div><img src="images/location.png" /></div>
                    {!onOff && <div>My Location</div>}
                </div>
            </SearchBox>
        </Layout> : 
        <Layout>
           <ul>
                <div onClick={() => setCountryDrop(e => !e)} > 
                    <div>Country</div>
                    <img src="images/list.png" />
                </div>
            </ul>


            <SearchBox onOff={onOff}>
                <nav>
                    <input 
                        value={search}
                        type="text"
                        placeholder="City, Street, Address, Postcode"
                    />
                    <div>Search</div>
                </nav>
                
                <div>
                    <div><img src="images/location.png" /></div>
                    {!onOff && <div>My Location</div>}
                </div>
            </SearchBox>
        </Layout>
    )
}


export default Filter;



const Layout = styled.div`
    width: 100%;
    height:  ${(prop) => prop.onOff ? "" : "160px"};
    border-bottom: ${(prop) => prop.onOff ? "" : "solid 1px #AAAAAA"};
    display: flex;
    flex-direction: ${(prop) => prop.onOff ? "column" : "row"};
    align-items: center;
    justify-content: ${(prop) => prop.onOff ? "flex-start" : "space-between"};
    margin-top: ${(prop) => prop.onOff ? "24px" : "0"};
    gap: ${(prop) => prop.onOff ? "8px" : "0"};

    & > ul{
        width: ${(prop) => prop.onOff ? "341px" : "310px"};
        height: 38px;
        border: solid 1px #000000;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > div{
            width: 80%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            color: ${(prop) => prop.data === "Country" ? "#AAAAAA" : "#333333"};
            font-size: 14px;
            font-weight: 600;
            /* position: relative; */
        }

        & > nav{
            position: absolute;
            width: ${(prop) =>  prop.onOff ?  "325px" : "294px"};
            height: 176px;
            border: solid 1px #000000;
            background-color: #ffffff;
            border-radius: 2px;
            /* top: ${(prop) =>  prop.onOff ?  "245px" : "655px"}; */
            padding: 8px;
            overflow: auto;
            overflow-x: hidden;
            transition: all .3s;
            margin-top: 245px;
            z-index: 1;
            &::-webkit-scrollbar {
                    display: none; /*Chrome, Safari, Opera*/
                    width: 0px;
            } 

            & > div{
                width: 288px;
                height: 40px;
                display: flex;
                font-size: 14px;
                color: #333333;
                display: flex;
                align-items: center;
                gap: 16px;
                padding-left: 8px;
                cursor: pointer;

                & > img{
                    width: 40px;
                    height: 24px;
                }

                &:hover{
                    background-color: #000000;
                    color: #ffffff;
                    border-radius: 2px;
                    transition: all .3s;
                }
            }
        }
    }
`


const SearchBox = styled.div`
    display: flex;
    gap: 8px;

    & > nav{
        width: ${(prop) => prop.onOff ? "261px" : "438px"};
        height: 38px;
        padding: 0 16px;
        border: 1px solid #000000;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > input{
            width: 85%;
            font-size: 14px;
            border: none;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }

        & > div{
            color: #333333;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
        }
    }

    & > div{
        width: ${(prop) => prop.onOff ? "40px" : "152px"};
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 16px;
        color: #ffffff;
        background-color: #000000;
        border-radius: 2px;
        cursor: pointer;
    }
`