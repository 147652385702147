


function Sample2(){


    return(
        <div>
            <h4>샘플 2</h4>
        </div>
    )
}

export default Sample2;