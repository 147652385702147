
import React from 'react';
import { BrowserRouter as  Router, Routes, Route  } from "react-router-dom";
import Main from "./pages/main/Main";
import Error from "./pages/Error/Error";

function App() {
  // console = window.console || {};
  // console.log = function no_console() {}; // console log 막기
  // console.warn = function no_console() {}; // console warning 막기
  // console.error = function () {}; // console error 막기
 
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="*" element={<Error />}/>
      </Routes>
    </Router>
  );
}

export default App;
