// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKey,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId:  process.env.REACT_APP_PROJECTID,
  storageBucket:  process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId:  process.env.REACT_APP_MESSAGINGSENDERID,
  appId:  process.env.REACT_APP_APPID,
  measurementId:  process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const store = getStorage(app);

export {db, store}