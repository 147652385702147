// import GoogleMapMap from "../../component/map/GoogleMap";

import { useEffect, useState } from "react";
import { getStaticProps } from "../../db/storage";
import styled from "styled-components";
import Filter from "./Filter";
import UseWidhtEvent from "../../hook/setWidthEvent";
import MapContent from "./MapContent";


const Layout = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const Banner = styled.div`
    width: 100%;
    height:  ${(prop) => prop.onOff ? "157px" : "492px"};
    margin-top: 48px;
    background-image: ${(props) => `url(${props.src})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`
const Skeleton= styled.div`
    width: 100%;
    height:  ${(prop) => prop.onOff ? "157px" : "492px"};
    margin-top: 48px;
`

const Content = styled.div`
    width: ${(prop) => prop.onOff ? "343px" : "1280px"};
`

function Distributors(){
    const [banner, setBanner] = useState(null);
    const { onOff } = UseWidhtEvent(1130);
    const [ckickEvent, setCkickEvent] = useState(false);
    const [clickContry, setClickContry] = useState();
    const [placeSearch, setPlaceSearch] = useState('')
    const [disableCountry, setDisable] = useState()

    useEffect(() => {
        const banner = async() => {
            const data = await getStaticProps();
            
            if(data){
                setBanner(data[0])
            }
        }

        banner()
    },[])

    return(
        
        <Layout>
            
            <>
                { banner ? <Banner src={banner} onOff={onOff}/> : <Skeleton onOff={onOff}/>}
                <Content onOff={onOff}>
                    <Filter setCkickEvent={setCkickEvent} clickContry={clickContry} setClickContry={setClickContry} setPlaceSearch={setPlaceSearch} setDisable={setDisable}/>
                    <MapContent ckickEvent={ckickEvent}  clickContry={clickContry} placeSearch={placeSearch} disableCountry={disableCountry} />
                </Content> 
            </>

        </Layout>
    )
}


export default Distributors;