import { useState } from "react";
import styled from "styled-components";
import FaceBook from "../asset/Facebook_icon.svg"
import Instagram from "../asset/Instagram_icon.svg"
import { Link } from "react-router-dom";
import UseWidhtEvent from "../hook/setWidthEvent";




function Footer(){
    const [modal, setModal] = useState()
    const { onOff } = UseWidhtEvent(1130);

    const conditions = () => {
        setModal('condition')
    }

    const policy = () => {
        setModal('policy')
    }


    return(
        <FooterLay>
            <Layout onOff={onOff}>
                <Content>
                    <nav>
                        <div onClick={() => conditions()}>Terms and conditions</div>
                        <div onClick={() => policy()}>Privacy policy</div>
                    </nav>

                    <div>
                        <div>
                            <div><strong>HeadQuarters / R&D Center</strong></div>
                            <div><strong>Citech Co., Ltd</strong></div>
                            <div>11F, 932 Yangjae-daero, Songpa-gu</div>
                            <div>Seoul, South Korea</div>
                            <div>T. 82-1899-6042</div>
                            <div>E. rose_service@hifirose.com </div>
                        </div>

                        <div>
                            <div><strong>Contact</strong></div>
                            <div>Customer Service & Technical Questions: rose_service@hifirose.com</div>
                            <div>Community : https://community.roseaudio.kr</div>
                        </div>
                    </div>
                </Content>

                <div style={{display: "flex", gap: "10px", cursor: "pointer"}}>
                    <div onClick={() => window.open('https://www.facebook.com/go.hifirose', "_blank")}><img src={FaceBook} /></div>
                    <div onClick={() => window.open('https://www.instagram.com/hifi_rose/', "_blank")}><img src={Instagram} /></div>
                </div>
            </Layout>

            {modal === 'condition' && 
                <Modal>
                    <div style={{height: '5050px'}}>
                        <nav>
                            <div>Terms and conditions</div>
                            <div onClick={() => setModal()} style={{"cursor":'pointer'}}><img src="images/x_grey.png"/></div>
                        </nav>
                        <div>
                        Article 1 Purpose <br/>
                                The purpose of these Terms of Use is to set forth any and all matters regarding the terms and conditions of use and operation of services on “name of the website” (“Website”).
                                <br/><br/>
                                Article 2 Definitions The major terms used herein shall have the following meanings.
                                <br/>1) Members mean users who have registered with the Website by agreeing to the terms and conditions of the Website and providing their personal information and who have executed the user agreement with the Website and use the Website.
                                <br/>2) User Agreement means the agreement entered into by and between the Website and a Member with regard to the use of the Website.
                                <br/>3) Member’s ID means a combination of a unique sequence of characters and numbers, which is granted to each Member for the identification of the Member and the use of services by the Member.
                                <br/>4) Password means a combination of characters and numbers, which is selected by a Member to verify that the ID is granted to the same Member and to protect the rights and interests of the Member.
                                <br/>5) Operator means the person/entity that has opened and operated a website that provides services.
                                <br/>6) Termination means that the Members terminate the user agreement.
<br/>
                                <br/>Article 3 Applicable Rules Other than Standard Terms of Use
                                <br/>The Operator may separately announce and provide guidance on operational policies, if necessary. If these Terms of Use and operational policies overlap, then the operational policies shall prevail.
<br/>
                                <br/>Article 4 Execution of User Agreement
                                <br/>1) The user agreement shall be constituted when a person who wishes to use the Website agrees to these Terms of Use and then the Operator accepts the application for membership made by such a person.
                                <br/>2) Any person who wishes to use services as a Member shall indicate his/her intention to agree to these Terms of Use by reading these Terms of Use when applying for membership and clicking “I agree” in the below.
<br/>
                                <br/>Article 5 Application for the Use of Services
                                <br/>1) Any user who wishes to use the Website as a Member shall provide any and all information, such as User ID, Password, and nickname, which is requested by the Website.
                                <br/>2) A Member who has not registered his/her genuine information by stealing another person’s information or registering false information may not claim any right to the use of the Website and may be subject to punishment under relevant statutes.
<br/>
                                <br/>Article 6 Guideline on Personal Information Processing
                                <br/>The Website and the Operator do not have the Members’ Passwords among their personal information provided in applying for membership. Anything related to the Members’ Passwords shall be handled in accordance with the Guideline on Personal Information Processing.
                                <br/>The Operator shall make efforts to protect the Members’ personal information, including the Members’ registered information, in accordance with relevant statutes.
                                <br/>The Members’ personal information shall be protected in accordance with relevant statutes and the Guideline on Personal Information Processing that is established by the Website.
                                <br/>However, the Operator shall have no liability for the information leaked due to a reason attributable to a Member.
                                <br/>If a Member registers and distributes illegal postings, including those that disrupt good public order and customs or violate national security, then the Operator may access the Member’ materials and submit such materials to the related institutions, upon request of such institutions.
<br/>
                                <br/>Article 7 Operators’ Obligations
                                <br/>1) The Operator shall deal with any opinions offered or complaints raised by the Members as soon as possible if such opinions or complaints are deemed just. However, if the Operator finds it difficut to deal with such opinions or complaints swiftly due to its personal reasons, then it shall do its best by making a post announcement or sending the Members messages or e-mails.
                                <br/>2) If there is any breakdown in equipment or any equipment is lost, then the Operator may request the Website to repair or restore such equipment without any delay for the continuous and stable provision (or operation) of the Website. However, in case of natural disasters or unavoidable reasons on the part of the Website or the Operator, the operation of the Website may be temporarily suspended.
<br/>
                                <br/>Article 8 Members’ Obligations
                                <br/>1) The Members shall comply with the matters stipulated in these Terms of Use, any and all regulations established by the Operator, matters including notices and operational policies to which users are notified via the Website, and relevant statutes. The Members shall not commit acts to disrupt other businesses of the Website or to tarnish the reputation of the Website.
                                <br/>2) The Members may neither assign, give away, nor provide as security the right to use services and their contractual status under the user agreement, unless the Website gives its the express consent.
                                <br/>3) Customers shall take considerable care in managing their IDs and Passwords and may not allow any third party to use their IDs without the consent of the Operator or the Website.
                                <br/>4) The Members shall not infringe on the intellectual property rights of the Operator, the Website, and any third party.
<br/>
                                <br/>Article 9 Service Use Hours
                                <br/>1) In principle, services shall be available for 24 hours a day, 365 days a year unless there is any special operational or technological delay. However, the Website may temporarily suspend services on the date(s) or hour(s) designated by the Website for system checkups, extensions, and replacements. As the temporary suspension of services due to scheduled work is announced on the homepage of the Website in advance, users are recommended to visit and read the homepage frequently.
                                <br/>2) The Website may temporarily or permanently suspend services without any prior announcement or notice in the following cases.
                                <br/>- If there is any urgent system checkup, extension, replacement, breakdown, or malfunction
                                <br/>- If there is any force majeure event, such as national emergencies, blackouts, or natural disasters
                                <br/>- If common carriers as stipulated in the Telecommunications Business Act suspend telecommunication services
                                <br/>- If there is any delay in the use of normal services due to overload of traffic during the use of services
                                <br/>3) In case of the suspension of services under Paragraph 2 above, the Website shall notify the Members of such suspension via prior notice. However, if the Website finds it impossible to make a prior notice of the service suspension caused by an uncontrollable cause, then a later notice or announcement shall be made in lieu of such a prior notice.
<br/>
                                <br/>Article 10 Termination of Use of Services
                                <br/>1) If the Members wish to terminate the user agreement with the Website, then they themselves shall apply for the termination of their registration online. However, the termination of use of the Website shall be separately made from the termination of the user agreement.
                                <br/>2) As Website-related programs are automatically deleted on the Members Management screen, upon application for termination of the user agreement (or the use of services), the Operator may not be able to look at the information of the applicant for the termination of the user agreement anymore.
<br/>
                                <br/>Article 11 Limitations on Use of Services
                                <br/>The Members shall not conduct any act that falls under any of the following. If the Members are found to have committed any such act, the Website may place restrictions on the use of services by the Members, take legal measures, terminate the user agreement, or suspend services for a specified period of time.
                                <br/>1) Registering false information upon signing up for membership or changing information so that it is false after signing up for membership
                                <br/>2) Hindering others from using the Website or stealing others’ information
                                <br/>3) Impersonating the management, staff, or related personnel of the Website
                                <br/>4) Infringing on the moral rights or intellectual property rights of the Website or any other third party or disrupting their businesses or operations
                                <br/>5) Fraudulently using another Member’s ID
                                <br/>6) Collecting, storing, and disclosing another Member’s personal information without its consent
                                <br/>7) Committing any act that is objectively believed to be related to crimes
                                <br/>8) Violating other relevant statutes
<br/>
                                <br/>Article 12 Management of Postings
                                <br/>1) The Operator shall be responsible for the management and operation of postings and materials of the Website. The Operator shall always monitor postings and materials. If the Operator finds or receives a report on faulty postings and materials, then it shall delete such postings and materials and give a warning to the Member that registered such postings and materials.
                                <br/>Meanwhile, as the Member is responsible for postings made by him/her, he or she may not put up postings that violate these Terms of Use.
                                <br/>2) If public institutions, such as the Korea Internet Safety Commission, make a demand for the correction of any posting, then the Operator may delete or move such posting without the Member’s prior consent.
                                <br/>3) The criteria for determining any posting to be faulty are as follows.
                                <br/>- Postings that seriously insult another Member or any third party or tarnish its reputation
                                <br/>- Postings that distribute or offer links to content in violation of good public order and customs
                                <br/>- Postings that promote illegal copies or hacking
                                <br/>- Postings that aim to make advertisements for purposes of profit
                                <br/>- Postings that are objectively acknowledged to be associated with crimes
                                <br/>- Postings that infringe on the rights of another Member or any third party, including copyrights
                                <br/>- Other postings that are deemed to violate relevant statutes
                                <br/>4) If the Website and the Operator are requested by any third party to suspend putting up postings due to reasons such as defamation or infringement of rights, including intellectual property rights, then they may temporarily do so. In addition, if the Website is informed of any lawsuit, settlement between any person/entity requesting the suspension of putting up postings and any person/entity registering such postings, or any decision of a relevant institution similar to the foregoing, then it shall comply with such settlement or decision.
<br/>
                                <br/>Article 13 Custody of Postings
                                <br/>If the Operator suspends the operation of the Website due to unavoidable circumstances, then it shall make a prior announcement and make efforts to take all measures to ensure that all postings are easily transferred.
<br/>
                                <br/>Article 14 Copyrights to Postings
                                <br/>1) Members shall own copyrights to materials posted by them within the Website. In addition, the Website may not use such materials commercially without the consent of the Members making such postings. However, the Website has the right to use such materials for non-profitable purposes and to post such materials within services.
                                <br/>2) Members may not use materials posted in the services for commercial purposes, including processing and selling the information acquired through the services at their discretion.
                                <br/>3) If the Operator determines materials or content posted or registered by the Members to fall under any of the items in Paragraph of Article 12 above, then it may delete, move, or refuse to register such materials or content without any prior notice.
<br/>
                                <br/>Article 15 Damages
                                <br/>1) The Members shall primarily bear all civil and criminal liability that arises within the Website.
                                <br/>2) If a Member suffers from any damage caused by force majeure events, such as natural disasters or its willful misconduct or negligence, then the Website may offer no compensation for damages.
<br/>
                                <br/>Article 16 Indemnification
                                <br/>1) If Members do not earn any profit from the rendering of services at the Website or if there arises any damage from the optional selection of service materials or use of services, then the Operator shall be relieved from any liability arising from the foregoing.
                                <br/>2) The Operator shall be relieved from liability caused by any disruption in services at the Website or in telecommunication services offered by other carriers. These Terms of Use shall apply mutatis mutandis to the damage caused in connection with the service base at the Website.
                                <br/>3) The Operator shall not be liable for materials stored, posted, or transmitted by the Members.
                                <br/>4) The Operator shall not be liable for any disruption in the use of services due to a reason attributable to the Members.
                                <br/>5) The Operator shall not be liable for any and all activities, including data transmission and other community activities, between the Members, and between the Members and any third parties, regardless of whether such activities occur within the Website or through any outside services.
                                <br/>6) The Operator shall not be liable for the authenticity, trustworthiness, and accuracy of materials posted or transmitted by the Members or all materials provided for the Members via the Website.
                                <br/>7) The Operator shall not be liable for any and all damage which is caused by goods transactions between the Members, as well as between the Members and any third parties via services.
                                <br/>8) The Operator shall not be liable for any disputes that occur between the Members or between the Members and any third party without a reason attributable to the Operator.
                                <br/>9) The Operator shall not be liable for any damage suffered by the Members if such damage is caused by disruptions in systems that can occur without its willful misconduct or gross negligence. In this case, such misconduct can be considered similar to misconduct in the course of managing, checking, repairing, and replacing equipment, such as servers or operating software. The Operator is also not liable for any damage suffered by the Members if it is caused by any third party’s attack, the distribution of computer viruses for which any anti-virus/vaccines have not been developed by domestic or foreign prestigious research institutions or security-related companies, or force majeure events that cannot be otherwise controlled the Operator.
                                <br/>
                                <br/>
                                <br/>Addendum
                                <br/>These Terms of Use shall take effect on MM-DD-YYYY.
                        </div>
                    </div>
                </Modal>}

                {modal === 'policy' && 
                <Modal>
                    <div style={{height: '4850px'}}>
                        <nav>
                            <div>Privacy policyClose</div>
                            <div onClick={() => setModal()} style={{"cursor":'pointer'}}><img src="images/x_grey.png"/></div>
                        </nav>
                        <div>
                            <br/>Article 1 Consent to Collection of Personal Information and Collection Method
                            <br/>{'<'} The name of a company or website {'>'} (“URL” hereinafter “Website”) shall establish a procedure for allowing customers to click the button “Agree” to the terms of use, collection of personal information, and details of personal information used. Customers shall be deemed to have agreed to the collection and use of their personal information by clicking the “Agree” button.
                            <br/>
                            <br/>Article 2 Personal Information Items Collected and Purpose of Using Personal Information
                            <br/>“Personal Information” means information on living persons and refers to their names, resident registration numbers, or any other information that identifies such persons. (Even if such information alone cannot identify a certain person, such information that can be easily combined with other information and be used to identify such a person.)
                            <br/>
                            <br/>The Website has the following purposes for collecting and using customers’ personal information:
                            <br/>
                            <br/>Personal information of general members
                            <br/>- Time of collection: Signing up for membership
                            <br/>- Mandatory collection items: ID, password, e-mails, names *required to be revised*
                            <br/>- Optional collection items: Profile images, date of birth, telephone number, address *required to be revised*
                            <br/>- Purpose of using personal information: Signing up for membership, customer consulting for the use of services, and delivery of notices
                            <br/>- Retention period: Immediately deleted upon withdrawal of membership or retained for five (5) years for purchasing members
                            <br/>
                            <br/>Order information (including members and non-members)
                            <br/>- Time of collection: Upon placing orders
                            <br/>- Mandatory collection items: Information of customers placing orders (name, address, telephone, and e-mail), information on recipient (name, address, telephone), payment approval information
                            <br/>- Optional collection items: Delivery of messages
                            <br/>- Purpose of using personal information: Payment and delivery of ordered products
                            <br/>- Retention period: Retained for five (5) years
                            <br/>
                            <br/>Article 3 Collection of Personal Information via Cookies
                            <br/>The Website may install and operate cookies that store and frequently retrieve customers’ information. A cookie means a small amount of text files that a website sends to users’ computer browsers (Internet Explorer, and others)
                            <br/>1) Purposes of using cookies
                            <br/>- Providing differentiated information, depending on individuals’ interests
                            <br/>- Analyzing the access frequency or staying time of users, identifying users’ tastes and interests, and using them for target marketing and as a measure for service improvement
                            <br/>- Tracing the information on items purchased and items to which users pay attention, and providing tailor-made services
                            <br/>2) Operation of cookies and rejection of cookiesCookies are stored at the hard disk of users’ computers. Cookies identify users’ computers but do not personally identify users.
                            <br/>In addition, customers may accept or reject all cookies, or go through checks whenever cookies are stored by changing settings on their web browser.
                            <br/>However, if customers refuse to store cookies, they may not use some services that require them.
                            <br/>3) Method for changing settings to reject cookies
                            <br/>A. Internet Explorer
                            <br/>Directly change settings by clicking Tools {'>'} Internet Options {'>'} Personal Information tab on the upper menu of a web browser
                            <br/>B. Chrome
                            <br/>Directly change settings by clicking Menu icon on the upper right bar of a web browser {'>'}Settings {'>'}Advanced Settings on the bottom of the screen {'>'} Contents Setting button on Personal Information section `{'>'}` Cookies section
                            <br/>
                            <br/>Article 4 Periods of Retaining and Using Personal Information and Destruction of Personal Information
                            <br/>1) Customers’ personal information shall be destroyed without any delay after the purposes of collection and use of their personal information have been fulfilled. However, if customers’ personal information needs to be retained for a certain period of time for the following purpose of verifying transaction parties’ rights and obligations in accordance with provisions of relevant statutes, such as the Protection of Consumers in e-commerce and Other Transactions Act, such information shall be retained for the specified period:
                            <br/>A Article 6 of the Protection of Consumers in e-commerce and Other Transactions Act
                            <br/>- Records on contracts or withdrawal of offers: Retained for five (5) years
                            <br/>- Records on payments and the supply of goods: Retained for five (5) years
                            <br/>- Records on the resolution of customers’ complaints or disputes: Retained for three (3) years
                            <br/>B Article 15.2 of the Protection of Communication Secrets Act
                            <br/>- Log-in records: Retained for three (3) months
                            <br/>C Other related statutes
                            <br/>2) The Website shall destroy personal information in the following manner.
                            <br/>A. Destruction procedure-The information entered for membership sign-up shall be transferred to a separate database (in case of information on paper, a separate filing cabinet), stored for a certain period of time in accordance with internal guidelines and other relevant statutes, and then destroyed.
                            <br/>- The above personal information shall not be used for any purposes other than for the purposes stipulated by law.
                            <br/>B. Destruction methods-Personal information printed out on paper shall be destroyed by shredding or burning. - Personal information in electronic file format shall be entirely destroyed by technological methods so that they may not be restored or regenerated.
                            <br/>3) The Website shall give dormant members (who have not used services for the last twelve (12) months) a notice on the forfeiture of membership in accordance with Article 29.2 of the Act on Promotion of Information and Communications Network Utilization and Information Protection. If such members fail to reply to such a notice, they may be considered to have forfeited their membership at the Website’s discretion. In such a case, dormant members’ personal information may be stored and managed separately from other members’ personal information. Such personal information that is separated and stored shall be destroyed after the lapse of the statutory retention period. In case a customer makes a request, then such a customer’s personal information that is not destroyed shall be made available again at the time of resuming the use of services.
                            <br/>
                            <br/>Article 5 Provision of Personal Information for Third Parties
                            <br/>1) The Website shall neither use customers’ personal information nor provide such information for other persons, companies, and institutions beyond the scope of Article 1 above (Personal Information Items Collected and Purpose of Using Personal Information).
                            <br/>2) The following cases are exceptions.
                            <br/>A. When customers’ personal information is required by relevant institutions for investigative purposes in accordance with relevant statutes
                            <br/>B. When customers’ personal information is provided in a form that cannot identify certain individuals for advertisers, suppliers, or research organizations to compile statistics or conduct academic or market research
                            <br/>C. When customers’ personal information is requested in accordance with pre-determined procedures under other relevant statutesEven if personal information is provided in accordance with the foregoing, we do our best to ensure that such information is not indiscriminately provided against the original purpose of collection and use of such information.
                            <br/>
                            <br/>Article 6 Outsourcing of Personal Information Processing
                            <br/>The Website outsources the processing of users’ personal information to outside professional organizations as follows for the smooth conduct of businesses, such as provision of better services and customers’ convenience.
                            <br/>- Delivery of ordered products: OO Delivery Service *required to be revised*
                            <br/>- Establishing and maintaining computer systems: Hosting companies *required to be revised*
                            <br/>- Delivery tracking system service: Goodsflow Inc.
                            <br/>- Payment and escrow service: PG *required to be revised*
                            <br/>- Self-authentication, i-PIN service: Dream Security Inc.
                            <br/>※ The information shared with outsourcing companies shall be limited to the minimum information required to fulfill the original purpose of outsourcing. In addition, optional personal information is provided for outsourcing companies at the request of customers for services.
                            <br/>※ The list of outsourcing companies may be subject to change, depending on changed services and contractual periods. Any change in this list shall be announced in advance via notices. Customers participating in short-term events shall be individually notified of such events.
                            <br/>
                            <br/>Article 7 Access and Modification of Personal Information
                            <br/>
                            <br/>1) Customers may access or modify their personal information registered at the Website at any time. Customers may click the Change Members’ Information menu and directly access or modify their personal information. They may also request for such access or modification by sending e-mails or written requests to a chief privacy officer or a personal information handling employee at the Website. Then, the Website shall take relevant measures without any delay.
                            <br/>2) If customers demand the correction of any errors in their personal information, such personal information shall be neither used nor provided by the Website until such errors have been corrected.
                            <br/>3) If incorrect personal information has been already provided for any third party, then the Website shall immediately notify such third party of the result of correction of this information and have this third party also modify the information.
                            <br/>
                            <br/>Article 8 Withdrawal of Consent to Collection, Use, and Provision of Personal Information
                            <br/>1) Customers may withdraw their consent to the collection, use, and provision of their personal information that is made available when signing up for membership at any time. They may do so by clicking Withdrawal of Consent (Membership) in the Personal Information Management Menu on the initial landing page of the Website. They may also do so by contacting the chief privacy officer of the Website in writing, by telephone, or through e-mail. Then, the Website shall immediately take necessary measures, such as deletion of personal information. The Website shall immediately notify customers of such measures, including withdrawal of consent and destruction of personal information.
                            <br/>2) The Website shall take necessary measures to ensure that customers withdraw their consent (membership) to the collection of their information through an easier method than what they used to give their consent to the method of collecting personal information.
                            <br/>
                            <br/>Article 9 Measures for Ensuring the Security of Personal Information
                            <br/>The Website shall take technological/administrative/physical measures required for ensuring the security of personal information in accordance with Article 29 of the Personal Information Protection Act.
                            <br/>1. Encryption of personal information
                            <br/>Users’ personal information, including passwords, is stored and managed and is only known to the users who own that information. Important data is secured with separate features, such as the encryption and locking of files and transmitted data.
                            <br/>2. Technological measures against hacking
                            <br/>The Website shall install, regularly update, and check security porgrams to ensure that personal information is not leaked and damaged due to hacking or computer viruses. It shall also install systems in areas to which access from the outside is controlled, as well as technologically/physically inspect and block such areas.
                            <br/>
                            <br/>Article 10 Protection of Personal Information of Children under Fourteen Years of Age
                            <br/>The Website deems the protection of children’s personal information in online environments to be also important. It does not allow children under fourteen years of age who require the consent of their legal counsel to apply for membership. If such childern sign up for the Website or provide their personal information due to the theft of their names and information or abuse of systems, then their legal counsels may exercise all rights.
                            <br/>
                            <br/>Article 11 Chief Privacy Officer
                            <br/>The Website appoints the following chief privacy officer who is responsible for the handling of personal information and the handling of customers’ complaints regarding personal information and damage reliefs.
                            <br/>
                            <br/>▶ Chief Privacy Officer
                            <br/>- Name: *required to be revised”
                            <br/>- Title: *required to be revised”
                            <br/>- Job grade: *required to be revised”
                            <br/>- Contact point: *required to be revised”
                            <br/>
                            <br/>Article 12 Modification of the Guideline on Personal Information Processing
                            <br/>This guideline on personal information processing shall take effect on its effective date. Any addition of change under statutes and this guideline, and deletion and correction of anything in this guideline shall be announced via notices seven (7) days prior to the effectuation of such addition, deletion, or correction.
                        </div>
                    </div>
                </Modal>}
        </FooterLay>
    )
}


export default Footer;

const FooterLay = styled.footer`
    width: 100%;
    height: 342px;
    display: flex;
    margin-top: 169px;
    align-items: center;
    justify-content: center;
`

const Layout = styled.div`
    width: ${(prop) => prop.onOff ? "343px" : "1280px"};
    height: 100%;
    display: flex;
    justify-content: flex-start;
`

const Content = styled.div`
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > nav{
        font-size: 12px;
        display: flex;
        height: 22px;
        & > div{
            padding: 3px 15px 0 0;
            cursor: pointer;
            transition: all .3s;

            &:hover{
                opacity: 0.7;
            }
        }
    }
    
    & > div{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 29px;
        & > div{
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-size: 14px;
        }
        & > div > div > strong{
            font-size: 18px;
        }
    }
`

const Modal = styled.div`
    top: 0;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: auto;
    justify-content: center;

    & > div{
        width: 600px;
        margin-top: 80px;
        background-color: #ffffff;
        color: #212121;
        
        & > nav{
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            font-weight: 500;
        }

        & > div{
            padding: 24px;
            line-height: 20px;
        }
    }

`