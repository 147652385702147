import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import UseWidhtEvent from "../../hook/setWidthEvent";
import { getAllData } from "../../db/query";
import { RequestType, fromAddress, geocode, setDefaults } from "react-geocode";
import Mapshow from "./MapShow";
import List from "./List";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'




const Layout = styled.div`
    width: 100%; 
    border-bottom: 1px solid #AAAAAA;
    margin-top: 40px;
    display: flex;
    flex-direction:  ${(prop) => prop.onOff ? "column-reverse" : "row"};
    gap: 8px;
    padding-bottom: 40px;
`

const SideLayout = styled.div`
    width: ${(prop) => prop.onOff ? "343px" : "312px"};
`

const Filter = styled.div`
    width: 100%;

    & > div:nth-child(1) {
        display: flex;
        gap: 8px;
        height: 40px;

        & > nav{
            width: ${(prop) => prop.onOff ? "167.5px" : "150px"};
            height: 38px;
            border: 1px solid #AAAAAA;
            display: flex;
            align-items: center;
            color: #AAAAAA;
            font-size: 14px;
            padding-left: 8px;
            cursor: pointer;
            transition: all .3s;
            &:nth-child(1){
              background-color: ${(prop) => prop.entity === "Distributors" ? '#000000' : '#ffffff'};
              color: ${(prop) => prop.entity === "Distributors" ? '#ffffff' : '#AAAAAA'};
            }

            &:nth-child(2){
              background-color: ${(prop) => prop.entity === "Dealers" ? '#B18658' : '#ffffff'};
              color: ${(prop) => prop.entity === "Dealers" ? '#ffffff' : '#AAAAAA'};
            }
        }
        margin-bottom: 8px;
    }

    & > nav{
        width:  ${(prop) => prop.onOff ? "333px" : "302px"};
        height: 38px;
        border: 1px solid #AAAAAA;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 8px;

        & > input{
            font-size: 14px;
            border: none;
            background-color: transparent;
            background-image: url("images/search.png");
            background-repeat: no-repeat;
            padding-left: 30px;
            background-position: 5px center;
            &:focus{
                outline: none;
            }
        }

        & > div{
            color: #AAAAAA;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
        }
    }

    & > div:nth-child(3){
        width: ${(prop) => prop.onOff ? "343px" : "312px"};
        height: 572px;
        margin-top: 8px;
        overflow: scroll;


        &::-webkit-scrollbar {
                    display: none; /*Chrome, Safari, Opera*/
                    width: 0px;
        } 

        & > div {
          cursor: pointer;
          color: #333333;
          border: 1px solid #AAAAAA;
          width: ${(prop) => prop.onOff ? "325px" : "294px"};
          height: ${(prop) => prop.onOff ? "119px" : "129px"};
          padding: 8px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          font-size: 14px;
          margin-bottom: 8px;
          transition: all .3s;

          & > nav{
            width: 100%;
            display: flex;
            justify-content: space-between;

            & > div{
              font-size: 14px;
              color: #acacac;
              font-weight: 500;
            }
          }

          & > div{
            font-size: 12px;
          }


        }
    }
`


const MapLayer = styled.div`
    width: ${(prop) => prop.onOff ? "343px" : "960px"};
    height: ${(prop) => prop.onOff ? "400px" : "668px"};
`


function MapContent({ckickEvent, clickContry, placeSearch, disableCountry}){
    const [location, setLocation] = useState();
    const { onOff } = UseWidhtEvent(1130);
    const [AllDAta, setAllData] = useState()
    const [defaultProps, setDefaultProps] = useState({
      center: {
        lat: 10.99835602,
        lng: 77.01502627
      },
      zoom: 1
    })
    const [clickEntity, setClickEntity] = useState('all')
    const [listClick, setListClick] = useState()
    const [searchName, setSearchName] = useState()
    const [searchByName, setSearchByName] = useState('')
    const [hoveredMarker, setHoveredMarker] = useState(null);

    setDefaults({
      key: process.env.REACT_APP_VITE_GOOGLE_KEY
    })
  


    const onSuccess = (mylocation) => {
        setLocation(mylocation)
    }


    const onError = (error) => {
        console.log("error",error)
    }

    
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(onSuccess, onError)
    },[ckickEvent])


    useEffect(() => {
        if (location) {
            setDefaultProps({
              center: {
                lat: location.coords.latitude,
                lng: location.coords.longitude
              },
              zoom: 8
            })
        }
      }, [location, ckickEvent]);

    
    const All = async() =>{
      const data = await getAllData();
      if(data.length > 0 ){
        setAllData(data)
      }
    }

    
    useEffect(() => {
      All()
    },[])

    const placeGetlatlng = (text, num) => {
        fromAddress(text).then(({results}) => {
          if(results.length > 0 ){
            setDefaultProps({
              center: {
                lat: results[0].geometry.location.lat,
                lng: results[0].geometry.location.lng
              },
              zoom: num
            })
          }
        }).catch(error => {
           console.log(error)
        })
    }
    
    useEffect(() => {
      if(clickContry){
        if(clickContry[0] !== 'Country'){
          placeGetlatlng(clickContry[0], 5)
        }
      }
    },[clickContry])

        
    useEffect(() => {
      if(placeSearch !== ''){
        placeGetlatlng(placeSearch, 10)
      }
    },[placeSearch])

        
    useEffect(() => {
      if(searchByName !== ''){
        
        const data = AllDAta.find((data) => {
          return data.company.includes(searchByName) && data.address;
        });

        if (data) {
          setDefaultProps({
            center: {
              lat: data?.lat,
              lng: data?.lng
            },
            zoom: 15
          })
        }
      }
    },[searchByName])






    const onClickEntity = (entity) => {
      switch(entity){
        case 'Distributors':
          if( clickEntity !== 'Distributors'){
            setClickEntity('Distributors')
          }else{
            setClickEntity('all')
          }
          break;

        case 'Dealers':
            if( clickEntity !== 'Dealers'){
              setClickEntity('Dealers')
            }else{
              setClickEntity('all')
            }
            break;
      }
    }


    

    return(
      <Layout onOff={onOff}>
            <SideLayout onOff={onOff}>
                <Filter onOff={onOff} entity={clickEntity}>
                    <div>
                        <nav onClick={() => onClickEntity('Distributors')}>Distributors</nav>
                        <nav onClick={() => onClickEntity('Dealers')}>Dealers</nav>
                    </div>

                    <nav>
                        <input
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            type="text"
                            placeholder="Search by Name"
                            onKeyDown={ e =>  e.key === "Enter" && setSearchByName(searchName)}
                        />
                        <div onClick={() => setSearchByName(searchName)}>Search</div>
                    </nav>

                    <List 
                      hoveredMarker={hoveredMarker}
                      clickContry={clickContry}
                      AllDAta={AllDAta}
                      searchByName={searchByName}
                      disableCountry={disableCountry} 
                      listClick={listClick} 
                      setListClick={setListClick} 
                      setHoveredMarker={setHoveredMarker}
                      setDefaultProps={setDefaultProps}
                      clickEntity={clickEntity}
                    />
                </Filter>
            </SideLayout>

            <MapLayer onOff={onOff}>
              <Mapshow 
                defaultProps={defaultProps} 
                AllDAta={AllDAta} 
                clickEntity={clickEntity}
                clickContry={clickContry}
                searchByName={searchByName}
                disableCountry={disableCountry}
                hoveredMarker={hoveredMarker !== null ? hoveredMarker : null}
                setHoveredMarker={setHoveredMarker}/>
            </MapLayer>
    
        </Layout>

    )
}


export default MapContent;


