
import LeftNavDom from './LeftNavDom';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Menu } from '../utils/Menu';
import { AnimatePresence, motion } from 'framer-motion';

const Layout = styled(motion.div)`
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 0;
    display: ${(props) => props.menuOn ? "block" : "none"};
`
const SidMenu = styled(motion.div)`
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 0;
`

const NaviBar = styled(motion.ul)`
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    & > div{
        background-color: #2b2b2b;
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        display: flex;
        align-items: center;
        padding:20px;
        font-weight: normal;
    }
`;


const List = styled(motion.li)`
        position: relative;
        border-top: 1px solid #f3f3f3;

        & > nav {
            padding: 13px 50px 14px 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 12px;
            color: rgba(33, 33, 33, 0.89);
            letter-spacing: 0px;

        }

        .dropLayout {
            cursor: pointer;
            width: 280px;
            top: 70px;
            color: rgba(33, 33, 33, 0.89);
            letter-spacing: 0px;

            & > div{
                padding: 8px 50px 8px 30px;
                line-height: 1;
                font-size: 11px;
            }
        }
`

const XLOGO = styled.div`
    position: absolute;
    left: 265px;
    top: 9px;
    color: #ffffff;
    font-size: 18px;
    z-index: 1100;
    padding: 8px;
    cursor: pointer;
`


function Left({menuOn, setMenuOn}){
    const [click, setClick] = useState();

    const onClick = (data) => {
        setClick(prevState => {
            return {
              ...prevState,
              [data]: !prevState[data],
            };
        });
    }

    useEffect(() => {
        setClick(() => {
            let init = {}
            Menu.map((data) => {
                init[data.route] = false
            })
            return init
        })
    },[])

    const variants = {
        open: {  x: 0 },
        closed: { x: "-100%"}
    }

    return(
            <Layout menuOn={menuOn}>
                <AnimatePresence>
                    <SidMenu 
                        initial={false}
                        variants={variants}
                        animate={menuOn ? "open" : "closed"}
                        transition={{ duration: 0.2 }}>   

                        <NaviBar >
                            <div onClick={() => setMenuOn(e => !e)}>Sign-in required.</div>
                            {
                                Menu.map((data) => (
                                    <List> 
                                        <motion.nav onClick={() => onClick(data.route)}>{data.route}</motion.nav>
                                        <LeftNavDom data={data} menuOn={click && click[data?.route] } />
                                    </List>
                                ))
                            }
                            
                        </NaviBar>
                        <XLOGO onClick={() => setMenuOn(e => !e)}>X</XLOGO>
                    </SidMenu>
                 
                </AnimatePresence>
            </Layout>
    )
}


export default Left;