import { GoogleMap, useJsApiLoader, Marker, InfoWindow, MarkerClusterer, MarkerF, InfoWindowF, MarkerClustererF } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';


const Layout = styled.div`
  width: 100%;
  height: 100%;
  transition: all .3s;

  .gm-style .gm-style-iw-c {
    display: ${(prop) => prop.mapHoverMarker  ? 'block'  : 'none'};
    background-color: ${(prop) => prop.mapHoverMarker === "Dealers" ? '#B18658'  :  '#000000'};
    box-shadow: none;
    color: #ffffff;
    width: 300px;
  }

  .gm-style .gm-style-iw-tc::after{
    display: ${(prop) => prop.mapHoverMarker  ? 'block'  : 'none'};
    background-color: ${(prop) => prop.mapHoverMarker === "Dealers"? '#B18658'  : '#000000'};
  }
 
  .gm-style-iw-d::-webkit-scrollbar{
     display: none;
  }

  .gm-ui-hover-effect{
    display: none !important;
  }

`

const HoverMaker= styled.div`
  width: 90%;
  height: 146px;
  border-radius: 5px;
  font-size: 1em;
  color: #ffffff;
  transition: all .3s;
  & > p{
    font-size: 0.9em;
    margin-top: 8px;

    & > div{
      border-bottom: 1px solid #ffffff;
      width: 25px;
      overflow: initial;
      margin-top: 8px;
    }
  }
`


const containerStyle = {
  width: '100%',
  height: '100%'
};

const markerOption1 = {
  // 아이콘으로 사용할 이미지의 URL 또는 경로
  icon: {
    url: 'images/Dealers_maker.png',
    // 마커의 크기 지정
    scaledSize: new window.google.maps.Size(20, 20),
    // 아이콘의 anchor 지점 지정
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(20, 40)
  }
};

const markerOption2 = {
  // 아이콘으로 사용할 이미지의 URL 또는 경로
  icon: {
    url: 'images/Distributors_maker.png',
    // 마커의 크기 지정
    scaledSize: new window.google.maps.Size(20, 20),
    // 아이콘의 anchor 지점 지정
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(20, 40)
  }
};

const markerOption3 = {
  // 아이콘으로 사용할 이미지의 URL 또는 경로
  icon: {
    url: 'images/Dealers_click.png',
    // 마커의 크기 지정
    scaledSize: new window.google.maps.Size(22, 27),
    // 아이콘의 anchor 지점 지정
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(20, 40)
  }
};

const markerOption4 = {
  // 아이콘으로 사용할 이미지의 URL 또는 경로
  icon: {
    url: 'images/Distributors_click.png',
    // 마커의 크기 지정
    scaledSize: new window.google.maps.Size(22, 27),
    // 아이콘의 anchor 지점 지정
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(20, 40)
  }
};




function Mapshow({defaultProps, AllDAta, clickEntity, clickContry, searchByName, disableCountry, hoveredMarker, setHoveredMarker}){
  const apiKey = process.env.REACT_APP_VITE_GOOGLE_KEY
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  })

  const mapOptions = {
    styles : mapStyles,
    minZoom: 1,
    maxZoom: 20,
    mapTypeControl: false
  }


  const handleMarkerClick = (data) => {
    setHoveredMarker(data);

  };

  const [mapHoverMarker, setMapHoverMaker] = useState(null)
  const [filteredData, setFilteredData] = useState([]);
  const [map, setMap] = useState(null);

  useEffect(() => {
    setMapHoverMaker(hoveredMarker)
  },[hoveredMarker])


  useEffect(() => {

    if(AllDAta && clickContry){
      const filtered = AllDAta?.filter(data =>
        (clickEntity === 'all' || clickEntity === data.entity) &&
        (clickContry[0] === 'Country' || clickContry[0] === data.country) &&
        (searchByName === '' || data.company.includes(searchByName)) &&
        data.lat !== 0 &&
        data.lng !== 0 &&
        !disableCountry?.some(country => country[0] === data.country)
      );
      setFilteredData(filtered);
    }
  }, [AllDAta, clickEntity, clickContry, searchByName, disableCountry]);


  const smoothZoomIn = useCallback((map, targetZoom, targetCenter) => {
    let currentZoom = map.getZoom();
    let step = 1;
    let timer;

    // 재귀 함수로 부드러운 애니메이션 구현
    function zoom() {
      map.setZoom(currentZoom + step);
      currentZoom += step;

      if (currentZoom < targetZoom) {
        timer = setTimeout(zoom, 80); 
      } else {
        map.setCenter(targetCenter);
        clearTimeout(timer);
      }
    }

    zoom();
  }, []);

  const handleClusterClick = useCallback((cluster) => {
    if (!map) return; // map이 없으면 함수 종료
    // 클러스터의 중심 위치와 줌 레벨 가져오기
    const clusterCenter = cluster.getCenter();
    const clusterZoom = map.getZoom() + 1;

    // 부드러운 줌 인 애니메이션 적용
    smoothZoomIn(map, clusterZoom, clusterCenter);
    setMap(map);

  }, [map, smoothZoomIn]);




  return isLoaded ? (
    
    <Layout mapHoverMarker={mapHoverMarker?.entity}>
    
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultProps.center}
        options={mapOptions}
        zoom={defaultProps.zoom}
        onClick={() => {
          setHoveredMarker(null)
        }}
      >
      {mapHoverMarker && (
          <InfoWindowF
            position={{ lat: mapHoverMarker?.lat, lng: mapHoverMarker.lng }}
            onCloseClick={() => {
              setHoveredMarker(null)
            }}
            options={{ 
              pixelOffset: new window.google.maps.Size(-10, -40)
            }}
          >
            <HoverMaker>
              <h3>{mapHoverMarker.company}</h3>
              <p>{mapHoverMarker.address} <div /></p>
              <p>TEL : {mapHoverMarker.contact}</p>
              {mapHoverMarker.email !== '' && <p> E-mail : {mapHoverMarker.email}</p>}
              <p>URL : {mapHoverMarker.homepage}</p>
            </HoverMaker>
          </InfoWindowF>
      )}
      <MarkerClusterer
          options={{
          imagePath: 'images/cluster',
          styles: [
            {
              textColor: 'white',  // 클러스터 텍스트 색상
              textSize: 14,        // 클러스터 텍스트 크기
              width: 40,           // 클러스터 아이콘 너비
              height: 40,          // 클러스터 아이콘 높이
              url: 'images/cluster3.png'
            },
            {
              textColor: 'white',
              textSize: 14,
              width: 60,
              height: 60,
              url: 'images/cluster3.png'
            },
            // 추가적인 클러스터 스타일 설정 가능
          ]}}
          onClick={handleClusterClick}
        >
      {clusterer =>
        filteredData?.map(( data, index ) => (
            <MarkerF
              key={index}
              position={{lat: data?.lat, lng : data?.lng}} 
              options={data.entity === "Distributors" ? data === mapHoverMarker ? markerOption4 : markerOption2 : data === mapHoverMarker ? markerOption3 : markerOption1}
              onClick={() => handleMarkerClick(data)}
              clusterer={clusterer}
               />
          )) }
      </MarkerClusterer>
      </GoogleMap>
       
    </Layout>
    ) : <Skeleton count={42}/>
}




export default React.memo(Mapshow);


const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]