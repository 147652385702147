import styled from "styled-components";
import { Menu } from "../utils/Menu";
import useHandleUrl from "../hook/setHandleURL";
import UseWidhtEvent from "../hook/setWidthEvent";
import Menu_icon from "../asset/Menu_icon";
import { useSelector } from "react-redux";


const Layout = styled.div`
    width: 100vw;
    height: ${(prop) => prop.onOff ? "48px" : prop.headerHeight ? "60px" : "70px"};
    background-color: #000000;
    list-style: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content:  ${(prop) => prop.onOff? "none" : "center"};
    position: fixed;
    z-index: 2;
   
    & > div{
        width: 1280px;
        display: flex;
        flex-direction: flex-start;
        align-items: center;
        margin-left: ${(prop) => prop.onOff ? "8px" : prop.headerLogo ? "35px" : "51px"};

        & > div{
            width: 100%;
            display: flex;
            gap: 20px;
            padding-left:  ${(prop) => prop.smallOnoff? "3px" : ""};
            & > div{
                display: flex;
                align-items: center;
                justify-content: center;
                width:${(prop) => prop.onOff ? "85%" : null};
            }
    
            & > nav {
                display: flex;
                align-items: center;
                justify-content: center;
    
                & > svg{
                    width: 22px;
                    color: #ffffff;
                    cursor: pointer;
                }
            }
        }
    }


    
`

const NaviBar = styled.ul`
    width: 85%;
    display: flex;
    font-family:  "Quicksand", "KakaoLight", sans-serif;
    margin-left: 25px;

    & > li {
        position: relative;




        & > nav {
            height: 70px;
            padding: ${(prop) =>  prop.headerHeight ? "0 15px" : "0 20px"};
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: ${(prop) =>  prop.headerHeight ? "12px" : "14px"};
            letter-spacing: 1.5px;
            font-weight: inherit;
            font-style: normal;
            display: table-cell;
            vertical-align: middle;

            &:hover {
                & + .dropLayout {
                    display: block;
                    
                }
                color: rgba(201, 224, 253, 0.3);
                transition: .4s;
            }
        }

        .dropLayout {
            cursor: pointer;
            display: none;
            position: absolute;
            width: 100px;
            background-color: #000000;
            font-size: 13px;
            top: 70px; 
            box-shadow: none;
            border: 0px solid #e5e5e5;
            min-width: 160px;
            font-style: normal;

            &:hover{
                display: block;
                
            }

            & > div{
                color: #ffffff;
                padding: 10px 20px;
                letter-spacing: 0px;
                font-weight: inherit;
            }
        }

        .dropMenu:hover{
            background-color: rgba(68, 68, 68, 0.9);
            transition: .3s;
        }
    }
`;



function Header({setMenuOn}){
    const  handleButtonClick = useHandleUrl()
    const { onOff } = UseWidhtEvent(990)
    const { onOff: headerLogo } = UseWidhtEvent(1300)
    const { onOff: headerHeight } = UseWidhtEvent(1030)
    const { onOff:smallOnoff } = UseWidhtEvent(400)
    const routeState = useSelector(state => state.auth)
    
    
    return(
        <Layout onOff={onOff} smallOnoff={smallOnoff} headerLogo={headerLogo} headerHeight={headerHeight}>
            <div>
                <div>
                    { onOff ? <nav onClick={() => setMenuOn((e) => !e)}><Menu_icon /></nav> : null }
                    <div>
                        <img src="images/logo.png" style={{"width" :  onOff ?  30 : headerLogo ? 65 : 78.5, "height" : "auto"}} />
                    </div>
                    { !onOff ? 
                    <NaviBar headerHeight={headerHeight}>
                        {
                            Menu.map((data) => (
                                <li> 
                                    <nav onClick={() => 
                                        handleButtonClick(data.url + data.path)} 
                                        style={{"border-bottom" : routeState.path === `/${data.route}` ? "solid 3px #ffff" : "none", 
                                        "fontWeight" :  routeState.path === `/${data.route}` ? "600" : "100"}}>{data.route}</nav>
                                    <div className="dropLayout">
                                        {data?.childNode && data?.childNode?.map( (child) => (
                                            <div className="dropMenu" onClick={() => handleButtonClick(data.url + child.path)}>{child.route}</div>
                                        ))}
                                    </div>
                                </li>
                            ))
                        }
                    </NaviBar> : null }
                </div>
            </div>
        </Layout>
    )
}

export default Header;