
export const Menu = [
    {
        "url": process.env.REACT_APP_HIFI_ROSE,
        "path" : "/index",
        "route" : "Home"
    },
    {
        "url": process.env.REACT_APP_HIFI_ROSE,
        "path": "/105",
        "route" : "About ROSE"
    },
    {
        "url": process.env.REACT_APP_HIFI_ROSE,
        "path": "/RS130",
        "route" : "Product",
        "childNode" : [
            {
                "path" : "/RS130",
                "route" : "RS130",
            },
            {
                "path" : "/RS150-RS150B",
                "route" : "RS150 / RS150B",
            },
            {
                "path" : "/RS250-RS250A",
                "route" : "RS250 / RS250A",
            },
            {
                "path" : "/RS201E-info",
                "route" : "RS201E",
            },
            {
                "path" : "/RS350-info",
                "route" : "RS350",
            },
            {
                "path" : "/RS301-info",
                "route" : "RS301",
            },
            {
                "path" : "/RA180",
                "route" : "RA180",
            },
            {
                "path" : "/RA280",
                "route" : "RA280",
            },
            {
                "path" : "/RS520",
                "route" : "RS520",
            },
            {
                "path" : "/RSA720",
                "route" : "RSA720",
            },
            {
                "path" : "/RSA780-info",
                "route" : "RSA780-info",
            },
            {
                "path" : "/93",
                "route" : "Rose Connect",
            }
        ]
    },
    {
        "url": process.env.REACT_APP_HIFI_ROSE,
        "path": "/warranty",
        "route" : "Support",
        "childNode" : [
            {
                "path" : "/warranty",
                "route" : "Warranty",
            },
            {
                "path" : "/notice",
                "route" : "Notice",
            },
            {
                "path" : "/download",
                "route" : "Download",
            },
            {
                "path" : "/faq",
                "route" : "FAQ",
            },
            {
                "path" : "/1162870229",
                "route" : "Event",
            },
            {
                "path" : "/178193383",
                "route" : "Review",
            },
            {
                "path" : "/ProductRegistration",
                "route" : "ProductRegistration",
            },
            {
                "path" : "/QnA",
                "route" : "Q&A",
            }
        ]
    },
    {
        "url": process.env.REACT_APP_HIFI_COMMU,
        "path" : "/",
        "route" : "Community"   
    },
    {
        "url":  process.env.REACT_APP_API_URL,
        "path": "/",
        "route" : "Dealers"
    }
];