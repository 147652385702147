import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";


const Layout = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
`

const Button = styled.div`
    padding: 10px 80px;
    border-radius: 5px;
    border: 1px solid #000000;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
    font-weight: 600;
`

function Error(){
    const [timer, setTimer] = useState(10);
    const navigate = useNavigate();


    useEffect(() => {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }, []);
    
    useEffect(() => {
      if(timer <= 0){
        navigate("/")
      }
    }, [timer]);



    return(
        <Layout>
            <h1>잘못된 접근입니다.</h1>
            <div>{timer}초 후 메인페이지로 돌아갑니다.</div>
            <Button onClick={() => navigate("/")}>돌아가기</Button>
        </Layout>
    )
}

export default Error;