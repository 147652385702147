import { useEffect, useState } from "react";
import Pagination from "../../component/pagination/Pagination";


function Sample1(){


    return(
        <div>
            <h4>샘플 1</h4>

          
        </div>
    )
}

export default Sample1;