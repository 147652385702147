
function useHandleUrl(){

    const handleButtonClick = (value) => {
        //console.log(value)
        window.location.href = value;
    };

   return handleButtonClick;

}

export default useHandleUrl;