import useHandleUrl from "../hook/setHandleURL";
import {motion } from 'framer-motion';


function LeftNavDom({data, menuOn}){
    const handleButtonClick = useHandleUrl();

    const variants = {
        open: { 
            opacity: 1,  
        },
        closed: { opacity: 0 },
    }
 
    return(
       menuOn && <motion.div 
            className="dropLayout"
            initial="closed"
            variants={variants}
            animate={menuOn ? "open" : "closed"}
            transition={{ duration: 0.2 }}
        >
            {data?.childNode && data?.childNode?.map( (child) => (
                <div className="dropMenu" onClick={() => handleButtonClick(data.url + child.path)} >{child.route}</div>
            ))}
        </motion.div>
    )
}

export default LeftNavDom;