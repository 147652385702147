import React from "react";
import Layout from "../../layout/Index";







function Main(){

    return(
        <>
            <Layout />
        </>
    )
}

export default Main;