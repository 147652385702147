import { ref, listAll, getDownloadURL, uploadBytes } from 'firebase/storage';
import { store } from './firebase';

export async function getStaticProps(){
    const fileRef = ref(store, 'banner/');
    // image/ 하위에 있는 모든 파일에 대한 참조
    const result = await listAll(fileRef);
    const urls = await Promise.all(
      result.items.map(async (item) => {
        const url = await getDownloadURL(item);
        return url;
      }),
    );
  
    return urls;
}

export async function getCountryImg(){
  const fileRef = ref(store, 'country/');
  // image/ 하위에 있는 모든 파일에 대한 참조
  const result = await listAll(fileRef);
  const urls = await Promise.all(
    result.items.map(async (item) => {
      const url = await getDownloadURL(item);
      return url;
    }),
  );

  return urls;
}