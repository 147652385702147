import { createGlobalStyle } from "styled-components";
import KakaoBold from "../font/KakaoBold.ttf"
import KakaoRegular from "../font/KakaoRegular.ttf"
import KakaoLight from "../font/KakaoLight.ttf"

export const GlobalStyle = createGlobalStyle`
	@import url("https://vendor-cdn.imweb.me/css/nanumbarungothic.css?v1");
	@import url("https://vendor-cdn.imweb.me/css/notosans.css");
	@import url('https://fonts.googleapis.com/earlyaccess/nanumgothic.css');
	@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
	@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
	@font-face {
		font-family: "KakaoLight";
		src: local('KakaoLight');
		font-size: normal;
		src: url(${KakaoLight}) format('truetype');
	}
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
`;