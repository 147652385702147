import { Suspense, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loading from "../component/loading/Loading";
import Left from "./Left";
import DomNavigation from "../navigation/DomNavigation";
import UseWidhtEvent from "../hook/setWidthEvent";




function Layout(){
    const { onOff } = UseWidhtEvent(990)
    const [menuOn, setMenuOn] = useState(false);

    return(
        <>
            <Header  setMenuOn={setMenuOn}/>
            <Suspense fallback={<Loading />}>
                <DomNavigation />
                {onOff ? <Left menuOn={menuOn} setMenuOn={setMenuOn}/> : null }
            </Suspense>
            <Footer />
        </>
    )

}


export default Layout;